import React from 'react';
 
class HowWeWork extends React.Component {
    render(){
        return (
            <section id="howWeWork" className="how-work ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>How We <span>Work</span></h2>
                        <p>We prioritize a customer-centric approach, using their feedback to guide our decisions and ensure we are meeting their needs. Our teams work collaboratively fostering a culture of transparency, trust, and respect, where everyone's ideas and contributions are valued and recognized.</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-how-work">
                                <h3>01.</h3>
                                <h4>Planning</h4>
                                <p>Planning is an integral part of our work process. Our planning process is agile and iterative, allowing us to adapt to changing circumstances and feedback from stakeholders.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-how-work">
                                <h3>02.</h3>
                                <h4>Designing</h4>
                                <p>Design is a key aspect of our work process. We employ user-centered design principles, conduct user research, and employ the latest design tools and techniques.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-how-work">
                                <h3>03.</h3>
                                <h4>Product Launch</h4>
                                <p>Product launch is a critical phase of our work process. We follow a data-driven approach, conducting thorough testing, and ensuring that our product is user-friendly and meets expectations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default HowWeWork;