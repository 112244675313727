import React from 'react';
 
class WhoWeAre extends React.Component {
    render(){
        return (
            <section className="who-we-are ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Who We <span>Are</span></h2>
                        <p>We are a technology startup that was founded with the goal of disrupting the status quo and creating innovative solutions to some of the most pressing problems faced by individuals and businesses today.</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-users"></i>
                                <h4>We are Professional</h4>
                                <p> Our team is made up of a diverse group of experienced professionals who are passionate about leveraging cutting-edge technology to drive meaningful change.</p>
                                <span>1</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-lightbulb-o"></i>
                                <h4>We are Passionate</h4>
                                <p>At our core, we are a team of problem-solvers who are constantly pushing the boundaries of what's possible and focused on making a positive impact on the world.</p>
                                <span>2</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-tag"></i>
                                <h4>We are Creative</h4>
                                <p>We believe that creativity is key to unlocking the full potential of technology, thus we are able to develop products that are intuitive, user-friendly, and truly innovative.</p>
                                <span>3</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-scissors"></i>
                                <h4>We are Designer</h4>
                                <p>We are group of experienced designers who are passionate about creating products that not only solve problems, but are also aesthetically pleasing and enjoyable to use.</p>
                                <span>4</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-linode"></i>
                                <h4>We are Unique</h4>
                                <p>We not only focus on the technical aspects of our products, but we also consider the social, environmental, and economic impact of our work.</p>
                                <span>5</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-life-ring"></i>
                                <h4>We are Support</h4>
                                <p>We believe customer support is not just about fixing problems, but also about building relationships. We go above and beyond to enable our customer's success.</p>
                                <span>6</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhoWeAre;