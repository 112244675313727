import React from 'react';
import MainBanner from '../PageBanners/MainBanner';
import Welcome from '../Common/Welcome';
import About from '../Common/About';
import WhoWeAre from '../Common/WhoWeAre';
import Skill from '../Common/Skill';
import HowWeWork from '../Common/HowWeWork';
import Services from '../Common/Services';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
 
class Home extends React.Component {
    render(){
        return (
            <>
                {/* Main Banner */}
                <MainBanner />
                
                {/* Welcome Area */}
                <Welcome />

                {/* About Area */}
                <About />

                {/* Who We Are Area */}
                <WhoWeAre />

                {/* How We Work */}
                <HowWeWork />

                {/* Skill Area */}
                <Skill />

                {/* Services Area */}
                <Services />

                {/* Contact Area 
                <Contact />
                */}
                
                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />

            </>
        );
    }
}
 
export default Home;