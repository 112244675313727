import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/font-awesome.min.css';
import '../src/assets/css/animate.css';
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';

import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navigation from './components/Navigation/Navigation';
import Preloader from './components/Common/Preloader';
import Home from '../src/components/Pages/Home';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

class App extends React.Component {

    state = {
        loading: true
    };

    componentDidMount(){
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }

    hashLinkScroll = () => {
        const { hash } = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    }

    render() {
        // TODO: Add SDKs for Firebase products that you want to use
        // https://firebase.google.com/docs/web/setup#available-libraries

        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        const firebaseConfig = {
            apiKey: "AIzaSyCPf5jIXUd3re6I2ZqFCzfUurSrSvPu4SA",
            authDomain: "datalights-website.firebaseapp.com",
            projectId: "datalights-website",
            storageBucket: "datalights-website.appspot.com",
            messagingSenderId: "387990762173",
            appId: "1:387990762173:web:c247e81430e9479f0cfdd4",
            measurementId: "G-30C41YL8D3"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        // eslint-disable-next-line no-unused-vars
        const analytics = getAnalytics(app);


        return (
            <Router onUpdate={this.hashLinkScroll}>
                <>
                    {this.state.loading ? <Preloader /> : ''}
                    <Navigation />
                    <Route path="/" exact component={Home} />
                </>
            </Router>
        );
    }
}

export default App;
