import React from 'react';
import aboutImg from '../../assets/images/about.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>About <span>Datalights</span></h2>
                        <p>Datalights is a startup with the mission to revolutionize the world by creating innovative products and solutions. Our company aims to simplify people's lives by using digital solutions and make an ever lasting impact.</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2>We Are <span>Products and Solutions</span></h2>
                                    <p>Custom development of products & solutions that enables our customers convert their business challenges to opportunities.</p>
                                </div>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Cloud Agnostic</li>
                                    <li><i className="fa fa-check"></i>High Performance</li>
                                    <li><i className="fa fa-check"></i>Scalable Architecture</li>
                                </ul>

                                <ul>
                                    <li><i className="fa fa-check"></i>Agility & Speed</li>
                                    <li><i className="fa fa-check"></i>Awesome Support</li>
                                    <li><i className="fa fa-check"></i>Quick Turnaround</li>
                                </ul>

                                {/*<Link to="#" className="btn btn-primary">Read More</Link>*/}
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;