import React from 'react';
import videoImg from '../../assets/images/video-img.jpg';
 
class Skill extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section className="skill-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-skill">
                                <div className="section-title">
                                    <h2>Some of Our <span>Skills</span></h2>
                                </div>
                                
                                <h3 className="progress-title">Cloud Programing</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "92%"}}>
                                        <div className="progress-value">92%</div>
                                    </div>
                                </div>
                                
                                <h3 className="progress-title">Web Development</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "97%"}}>
                                        <div className="progress-value">97%</div>
                                    </div>
                                </div>
                                
                                <h3 className="progress-title">Mobile App Development</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "90%"}}>
                                        <div className="progress-value">95%</div>
                                    </div>
                                </div>
                                
                                <h3 className="progress-title">Data Analytics</h3>
                                <div className="progress mb-0">
                                    <div className="progress-bar" style={{width: "95%"}}>
                                        <div className="progress-value">90%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-lg-6 col-md-12">
                            <div className="skill-video">
                                <img src={videoImg} alt="video-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Skill;